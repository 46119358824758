<template lang="pug">
  .wrapper
    MenuHeader
    router-view
    Footer
</template>
<script>
import MenuHeader from 'components/Header'
import Footer from 'components/Footer'

export default {
  name: 'MainLayout',
  components: {
    MenuHeader,
    Footer
  }
}
</script>
